<template>
    <div class="offers-index">
        <h1 class="offers-index__title">Últimas ofertas publicadas</h1>
        <section class="offers-index__section">
            <div class="flex flex-col">
                <div
                    v-for="(item, key) in pageData.page.data.offers.data"
                    :key="key"
                    :style="{ order: key + 1 }"
                    class="mb-3"
                >
                    <DCardNewAdmin v-if="item.is_admin" :info="item" />
                    <DCardMain v-else :info="item" in-stores-route="true" />
                </div>
            </div>
        </section>
    </div>
</template>

<script lang="ts" setup>
import { replacePlaceholders } from '~/util/textFunction'
import type { Api, ServicesQueryParameters } from '~~/global'

import { useDateFunctions } from '~/composables/DateFunctions'

const {
    public: { origin, siteName },
} = useRuntimeConfig()

const { currentDate } = useDateFunctions()

const currentDates = currentDate()
const Route = useRoute()

const { buildHeaders, baseURL, endpoints } = useApiConfig()

const headers = buildHeaders()

const { data: offersData } = await useAsyncData('offers-index-page', async () => {
    try {
        const pageData = await $fetch<Api.Responses.Pages.Offers>(endpoints.pages.offers.path, {
            method: 'GET',
            headers,
            baseURL,
            params: {
                // page: infiniteScrollMeta.meta.current_page,
                per_page: 30,
            } as ServicesQueryParameters.GetDiscounts,
        })

        return {
            pageData,
        }
    } catch (error) {
        throw createError({
            statusCode: 500,
            message: 'Algo salió mal',
        })
    }
})

if (!offersData.value) throw new Error('Offers data not found')

const { pageData } = offersData.value

// infiniteScrollMeta.data = pageData.page.data.offers.data
// infiniteScrollMeta.meta = pageData.page.data.offers.meta

// if (
//     !infiniteScrollMeta.meta.last_page ||
//     infiniteScrollMeta.meta.current_page === infiniteScrollMeta.meta.last_page
// ) {
//     infiniteScrollMeta.stop = true
// }

const [title, description] = replacePlaceholders(
    [
        [':month', currentDates.month || ''],
        [':day', String(currentDates.day) || ''],
        [':year', String(currentDates.year) || ''],
        [':site', siteName || ''],
    ],
    [pageData.page.title || '', pageData.page.description || ''],
)

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
    ...(Route.path !== '/' ? { robots: 'noindex' } : {}),
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})
</script>

<style lang="postcss" scoped>
.offers-index {
    @apply container;
    &__title {
        @apply my-3 text-center text-[28px] font-medium lg:text-[32px];
    }
    &__section {
        @apply mx-auto mb-5 max-w-5xl;
    }
}
</style>
